<template>
  <div>
    <h2 class="text-center mt-3 mb-3">Bukti Pembayaran Hutang</h2>
    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#transactionModal"
      @click="addData"
    >
      Upload
    </button>
    <div class="table-responsive">
      <div v-if="loading" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <table v-else class="table table-bordered table-hover mt-3 mb-1" id="transactions">
        <thead>
          <tr class="table-secondary">
            <th class="text-center">ID</th>
            <th>Tanggal Pembayaran</th>
            <th>Jumlah Pembayaran</th>
            <th>Bukti Pembayaran</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in transactions" :key="transaction.ID">
            <th class="text-center">{{ transaction.ID }}</th>
            <td>
              {{ transaction.PAYMENT_DATE }}
              <!-- {{new Date(transaction.PAYMENT_DATE)
                  .toISOString()
                  .slice(0, 11)
                  .replace("T", " ")}} -->
            </td>
            <td>{{ transaction.AMOUNT }}</td>
            <td>{{ transaction.PROOF }}</td>
            <td class="align-middle text-center">
              <i
                @click="editData(transaction.ID)"
                class="text-primary"
                data-bs-toggle="modal"
                data-bs-target="#transactionModal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                  />
                </svg>
              </i>
              <i
                @click="deleteData(transaction.id)"
                class="text-danger"
                data-bs-toggle="modal"
                data-bs-target="#transactionModal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </i>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="table-secondary">
            <th colspan="2">Total Pembayaran</th>
            <th>{{ totalPayment }}</th>
            <th></th>
            <th></th>
          </tr>
          <tr class="table-secondary">
            <th colspan="2">Sisa Pembayaran</th>
            <th>{{ remainingPayment }}</th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="transactionModal"
      tabindex="-1"
      aria-labelledby="transactionModalLabel"
      aria-hidden="true"
      ref="transactionModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="transactionModalLabel">
              {{ modalHeader }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="btnClose"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="authenticated && modalBody == 'add'">
              <form enctype="multipart/form-data">
                <div class="mb-3">
                  <label for="paymentDate" class="form-label">Tanggal Pembayaran</label>
                  <input type="date" class="form-control" id="paymentDate" v-model="paymentDate" />
                </div>
                <div class="mb-3">
                  <label for="amount" class="form-label">Jumlah Pembayaran</label>
                  <input type="number" class="form-control" id="amount" v-model="amount" />
                </div>
                <div class="mb-3">
                  <label for="amount" class="form-label">Bukti Pembayaran</label>
                  <input
                    type="file"
                    class="form-control"
                    id="proof"
                    ref="proof"
                    @change="selectFile"
                  />
                </div>
              </form>
            </div>
            <div v-else-if="authenticated && modalBody == 'edit'">
              <h2>Edit</h2>
            </div>
            <div v-else-if="authenticated && modalBody == 'delete'">
              <h2>Delete</h2>
            </div>
            <div v-else>
              <form>
                <div class="mb-3">
                  <label for="username" class="form-label">Username</label>
                  <input type="text" class="form-control" id="username" v-model="username" />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input type="password" class="form-control" id="password" v-model="password" />
                </div>
                <!-- <button type="submit" class="btn btn-primary">Submit</button> -->
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary" @click="onSubmit">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import JSZip from "jszip";
window.JSZip = JSZip;
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import axios from "axios"; //for api calling
// import { SERVER_HOST } from "../../server/utils/env.json";
// Local
// const apiUrl = "http://192.168.70.132:8081";
// Production
const apiUrl = "https://pop-db.davidrica.com";

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "IDR",
});

export default {
  mounted() {
    //Web api calling for dynamic data and you can also use into your demo project
    this.loading = true;
    axios
      .get(`${apiUrl}/api/get`)
      .then((res) => {
        this.transactions = res.data.transactions;
        this.calculateTotalPayment();
        this.loading = false;
        setTimeout(function () {
          $("#transactions").DataTable({
            pagingType: "full_numbers",
            pageLength: 5,
            processing: true,
            searching: false,
            lengthChange: false,
            columnDefs: [
              {
                targets: 2,
                orderable: false,
                render: function (data) {
                  return formatter.format(data);
                },
              },
              {
                targets: 3,
                orderable: false,
                render: function (data) {
                  if (/\.(xlsx|xls|xlsm)$/i.test(data)) {
                    return `<button type="button" class="btn btn-link" onclick="window.open('${apiUrl}${data}', '_blank').focus();">View</button>`;
                  }
                  return `<img src="${apiUrl}${data}" class="img-thumbnail" alt="Proof" onclick="window.open('${apiUrl}${data}', '_blank').focus();">`;
                },
              },
              {
                targets: 4,
                orderable: false,
              },
            ],
          });
        }, 100);
      })
      .catch(function (error) {
        console.log("error: ", error);
        alert("Something went wrong!");
      });
  },
  data: function () {
    return {
      transactions: [],
      modalHeader: "",
      username: "",
      password: "",
      paymentDate: null,
      amount: null,
      proof: null,
      modalBody: "",
      authenticated: false,
      loading: false,
      totalPayment: 0,
      remainingPayment: 0,
      initialDebt: 55528725, // 12 November 2023
    };
  },
  methods: {
    authenticate() {
      // UI Validation
      if (this.username == "davidrica" && this.password == "234234") {
        console.log("authenticated!");
        this.authenticated = true;
      }
    },
    selectFile() {
      this.proof = this.$refs.proof.files[0];
    },
    addData() {
      this.modalHeader = "Transaksi Baru";
      this.modalBody = "add";
    },
    editData(id) {
      this.modalHeader = "Edit Transaksi";
      this.modalBody = "edit";
      console.log("Edit ID ", id);
    },
    deleteData(id) {
      this.modalHeader = "Hapus Transaksi";
      this.modalBody = "delete";
      console.log("Delete ID ", id);
    },
    onSubmit() {
      if (this.authenticated && this.modalBody == "add") {
        const formData = new FormData();
        const newId = this.transactions.length + 1;

        formData.append("ID", newId);
        formData.append("PAYMENT_DATE", this.paymentDate);
        formData.append("AMOUNT", this.amount);
        formData.append("PROOF", this.proof);

        axios
          .post(`${apiUrl}/api/add`, formData)
          .then((res) => {
            console.log("Add response: ", res);
            if (res.data.status == "Success") {
              this.transactions = res.data.transactions;
              this.calculateTotalPayment();
              this.paymentDate = null;
              this.amount = null;
              this.proof = null;
              this.$refs.proof.value = null;
              this.$refs.btnClose.click();
            } else {
              alert("Something went wrong!");
            }
          })
          .catch(function (error) {
            console.log("error: ", error);
            alert("Something went wrong!");
          });
      } else {
        this.authenticate();
      }
    },
    calculateTotalPayment() {
      const paid = this.transactions.reduce(function (a, b) {
        const amount = parseInt(b.AMOUNT) > 0 ? parseInt(b.AMOUNT) : 0;
        return parseInt(a) + amount;
      }, 0);
      const paidWithInterest = this.transactions.reduce(function (a, b) {
        return parseInt(a) + parseInt(b.AMOUNT);
      }, 0);
      this.totalPayment = formatter.format(paid);
      this.remainingPayment = formatter.format(this.initialDebt - paidWithInterest);
    },
  },
};
</script>

<style scoped>
table.dataTable {
  border-collapse: collapse;
}
table.dataTable tfoot th,
table.dataTable tfoot td {
  padding-left: 10px;
  padding-right: 10px;
}
table.dataTable i {
  padding: 10px;
}
table.dataTable i:hover {
  cursor: pointer;
}
</style>

// Global style
<style>
.img-thumbnail {
  width: 50px;
  height: 50px;
}
.img-thumbnail:hover {
  cursor: pointer;
}
body {
  background-color: rgb(244, 245, 247);
}
</style>
