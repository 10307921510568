// App.vue
 
<template>
  <div id="app" class="container mb-5">
    <vue-data-table></vue-data-table>
  </div>
</template>
 
<script>
import VueDataTable from './components/VueDataTable'
 
export default {
  name: 'app',
  components: {
    VueDataTable
  }
}
</script>